import { useState } from 'react';
import './mobileMenu.css';

const MobileMenu = ({ children }) => {
  const [isOpen, toggleOpen] = useState(false);

  const toggleMenu = () => {
    toggleOpen(!isOpen);
  };

  return (
    <div id="menuToggle" onClick={toggleMenu} className={isOpen ? 'open' : null}>
      <span className='menu-icon'></span>
      <span className='menu-icon'></span>
      <span className='menu-icon'></span>
      <div id="menu">
        {isOpen ? children : null}
      </div>
    </div>
  );
};

export default MobileMenu;
