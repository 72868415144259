import { useState } from 'react';
import { Link } from 'react-router-dom';
import { toggleCirclesAnimation } from '../../helper';
import ShuffleText from '../ShuffleText';
import { ReactComponent as LinkIcon } from '../../assets/external.svg';
import MobileMenu from './MobileMenu';
import './style.css';

const Navbar = () => {
  const [isDark, toggleDark] = useState(false);

  const handleThemeChange = (e) => {
    e.preventDefault();
    document.body.classList.toggle('dark');
    document.querySelector('#girl-animate-displacement')?.beginElement();
    toggleCirclesAnimation(!isDark);
    toggleDark(!isDark);
  };
  const getMenuItems = (
    <>
      <Link to="/">
        <ShuffleText>Home</ShuffleText>
      </Link>
      <Link to="about">
        <ShuffleText>About</ShuffleText>
      </Link>
      <a
        href="https://codepen.io/gayane-gasparyan/pens/popular"
        target="_blank"
        rel="noreferrer"
      >
        <ShuffleText icon={<LinkIcon width={10} height={10} />}>
          Work
        </ShuffleText>
      </a>
      <a href="#" onClick={handleThemeChange}>
        {isDark ? (
          <ShuffleText key="static">normal</ShuffleText>
        ) : (
          <ShuffleText key="spice">add spice</ShuffleText>
        )}
      </a>
    </>
  );

  return (
    <>
      <div className="navbar">
        <Link to="/" className="logo">
          <span>Gg.</span>
        </Link>
        {getMenuItems}
      </div>
      <div className="navbar mobile-navbar">
        <Link to="/" className="logo">
          <span>Gg.</span>
        </Link>
        <MobileMenu>{getMenuItems}</MobileMenu>
      </div>
    </>
  );
};

export default Navbar;
