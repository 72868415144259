import { useEffect, useRef } from 'react';

const ShuffleText = ({ children, className = '', icon = null, }) => {
  const ref = useRef();
  const rootRef = useRef();
  const originalText = useRef();
  const intervalId = useRef();
  const timeoutId = useRef();

  useEffect(() => {
    const root = rootRef.current;
    const textNode = ref.current;
    originalText.current = textNode.textContent;
    root.addEventListener('mouseenter', onMouseEnter);
    root.addEventListener('mouseleave', onMouseLeave);
    return () => {
      root.removeEventListener('mouseenter', onMouseEnter);
      root.removeEventListener('mouseleave', onMouseLeave);
    };
  }, []);

  const shuffleBinary = () => {
    let binaryArray = Array.from({ length: originalText.current.length }, () =>
      Math.floor(Math.random() * 2)
    );
    binaryArray = binaryArray.map(() => Math.floor(Math.random() * 2));
    if (ref?.current?.textContent) {
      ref.current.textContent = binaryArray.join('');
    }
  };

  useEffect(() => {
    const a = setInterval(shuffleBinary, 100);

    setTimeout(() => {
      if (ref.current.textContent) {
        ref.current.textContent = originalText.current;
      }
      clearInterval(a);
    }, 500);
  }, []);

  const onMouseEnter = () => {
    intervalId.current = setInterval(shuffleBinary, 100);

    timeoutId.current = setTimeout(() => {
      if (ref.current.textContent) {
        ref.current.textContent = originalText.current;
      }
      clearInterval(intervalId.current);
    }, 500);
  };

  const onMouseLeave = () => {
    intervalId && clearInterval(intervalId.current);
    timeoutId && clearInterval(timeoutId.current);
    if (ref.current.textContent) {
      ref.current.textContent = originalText.current;
    }
  };

  return (
    <span ref={rootRef} className={`shuffle-text ${className}`}>
      <span ref={ref}>{children}</span>
      {icon}
    </span>
  );
};

export default ShuffleText;
