import { ReactComponent as CodepenIcon } from '../../assets/codepen.svg';
import { ReactComponent as LinkedinIcon } from '../../assets/linkedin.svg';
import { ReactComponent as GithubIcon } from '../../assets/github.svg';
import { ReactComponent as TwitterIcon } from '../../assets/twitter.svg';
import { ReactComponent as DeviantartIcon } from '../../assets/deviantart.svg';

const SocialLinks = () => {
  return (
    <div className="social-links">
      <a
        href="https://codepen.io/gayane-gasparyan"
        target="_blank"
        rel="noreferrer"
      >
        <span>
          <CodepenIcon />
        </span>
      </a>
      <a
        href="https://linkedin.com/in/gayanegasparyan"
        target="_blank"
        rel="noreferrer"
      >
        <span>
          <LinkedinIcon />
        </span>
      </a>
      <a
        href="https://github.com/Ggayane"
        target="_blank"
        rel="noreferrer"
      >
        <span>
          <GithubIcon />
        </span>
      </a>
      <a
        href="https://www.deviantart.com/gggayane"
        target="_blank"
        rel="noreferrer"
      >
        <span>
          <DeviantartIcon />
        </span>
      </a>
      <a
        href="https://twitter.com/gggayane"
        target="_blank"
        rel="noreferrer"
      >
        <span>
          <TwitterIcon />
        </span>
      </a>
    </div>
  );
};

export default SocialLinks;
