import React, { useEffect, useRef } from 'react';
import Matter from 'matter-js';
import SocialLinks from './SocialLinks';
import './style.css';

const FallingLetters = () => {
  const canvasRef = useRef(null);
  const requestRef = useRef(null);
  const containerRef = useRef(null);

  const renderBioText = () => {
    const bio = `software developer with over 9 years of experience, I have developed a strong foundation in crafting innovative and efficient technology solutions. My passion for technology and entrepreneurship led me to co-found Mythrill, where I currently serve as the CTO. I am proud to be recognized as one of the "30under30" Armenians in Tech and am constantly driven to push boundaries and make a positive impact in the industry. When I'm not coding, I enjoy exploring my creative side through art, music, and nature`;
    return bio.split(' ').map((word, index) => {
      const highlighted =
        word.startsWith(`"30under30"`) ||
        word.startsWith(`CTO`) ||
        word.startsWith(`Mythrill`);
      return (
        <span
          className={`box ${highlighted ? 'highlighted' : ''}`}
          key={`word-${index}`}
        >
          {word}
        </span>
      );
    });
  };

  const animate = () => {
    const Engine = Matter.Engine;
    const Render = Matter.Render;
    const World = Matter.World;
    const Bodies = Matter.Bodies;
    const Runner = Matter.Runner;
    const params = {
      isStatic: true,
      render: {
        fillStyle: 'transparent',
      },
    };
    const canvasSize = {
      width: window.innerWidth,
      height: window.innerHeight,
    };

    const engine = Engine.create({});

    const render = Render.create({
      element: containerRef.current,
      engine: engine,
      canvas: canvasRef.current,
      options: {
        ...canvasSize,
        background: 'transparent',
        wireframes: false,
      },
    });

    const floor = Bodies.rectangle(
      canvasSize.width / 2,
      canvasSize.height,
      canvasSize.width,
      200,
      params
    );
    const wall1 = Bodies.rectangle(
      0,
      canvasSize.height / 2,
      100,
      canvasSize.height,
      params
    );
    const wall2 = Bodies.rectangle(
      canvasSize.width,
      canvasSize.height / 2,
      100,
      canvasSize.height,
      params
    );
    const top = Bodies.rectangle(
      canvasSize.width / 2,
      0,
      canvasSize.width,
      150,
      params
    );
    const boxDomElements = document.querySelectorAll('.box');
    const boxBodies = [...boxDomElements].map((elemRef) => {
      const width = elemRef.offsetWidth;
      const height = elemRef.offsetHeight;

      return {
        body: Matter.Bodies.rectangle(canvasSize.width / 2, 0, width, height, {
          render: {
            fillStyle: 'transparent',
          },
        }),
        elem: elemRef,
        render() {
          const { x, y } = this.body.position;
          this.elem.style.top = `${y - 20}px`;
          this.elem.style.left = `${x - width / 2}px`;
          this.elem.style.transform = `rotate(${this.body.angle}rad)`;
        },
      };
    });
    // for mobile
    const root = window.innerWidth <= 479 ? render.canvas : document.body;
    const mouse = Matter.Mouse.create(root);
    const mouseConstraint = Matter.MouseConstraint.create(engine, {
      mouse,
      constraint: {
        stiffness: 0.2,
        render: {
          visible: false,
        },
      },
    });
    mouse.element.removeEventListener('mousewheel', mouse.mousewheel);
    mouse.element.removeEventListener('DOMMouseScroll', mouse.mousewheel);

    World.add(engine.world, [
      floor,
      ...boxBodies.map((box) => box.body),
      wall1,
      wall2,
      top,
      mouseConstraint,
    ]);
    render.mouse = mouse;
    Runner.run(engine);
    Render.run(render);
    (function rerender() {
      boxBodies.forEach((element) => {
        element.render();
      });
      Matter.Engine.update(engine);
      requestRef.current = requestAnimationFrame(rerender);
    })();
  };

  useEffect(() => {
    animate();
    return () => cancelAnimationFrame(requestRef.current);
  }, []);

  return (
    <div className="about-container" ref={containerRef}>
      {renderBioText()}
      <canvas ref={canvasRef} />
      <SocialLinks />
    </div>
  );
};

export default FallingLetters;
