import Drawing from '../components/Drawing';

function Home() {
  return (
    <article>
      <main>
        <div className='home-visual'>
          <Drawing />
        </div>
        <div className='container'>
          <h1>Hey there!</h1>
          <h1>I'm Gayane. I create things.</h1>
        </div>
      </main>
    </article>
  );
}

export default Home;
