import { ReactComponent as CursorSvg } from '../../cursor.svg';
import { useEffect, useRef } from 'react';
import './style.css';

function Cursor() {
  const cursorRef = useRef();
  const timeoutRef = useRef();
  let isMoving = false;

  useEffect(() => {
    const cursor = document.querySelector('.cursor');
    cursorRef.current = cursor;
    document.addEventListener('mousemove', trackCursor);

      setTimeout(() => {
        cursor.classList.remove('click');
      }, 500);
    
    return () => {
      document.removeEventListener('mousemove', trackCursor);
    };
  }, []);

  const trackCursor = (e) => {
    cursorRef?.current?.setAttribute(
      'style',
      `left: ${e.pageX - 100}px; top: ${e.pageY - 100}px`
    );
    if (!isMoving) {
      cursorRef?.current.classList.remove("rotate-cursor");
      isMoving = true;
    }
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      cursorRef?.current.classList.add("rotate-cursor");
      isMoving = false;
    }, 1000);
  };

  return <CursorSvg className="cursor" />;
}

export default Cursor;
