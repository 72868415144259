
import { useEffect } from 'react';
import { ReactComponent as Girl } from '../../girl.svg';
import { toggleCirclesAnimation } from '../../helper';
import './style.css';

function Drawing() {
 useEffect(() => {
  const show = document.body.classList.contains('dark');
  toggleCirclesAnimation(show);
 }, [])
 
  return <Girl className="girl-svg" />;
}

export default Drawing;
